<template>
    <v-icon class="pointer" small>
    {{icon}}
</v-icon>
</template>

<script>
export default {
    name: 'sx-icon',
    props: ['icon']
}
</script>

<style>

</style>